/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import * as styles from './basic.module.scss';
function _createMdxContent(props) {
  const {Editor} = Object.assign({}, _provideComponents(), props.components);
  if (!Editor) _missingMdxReference("Editor", true);
  return React.createElement("div", {
    className: styles.container
  }, React.createElement("div", {
    id: "toolbar-container",
    style: {
      borderTop: '1px solid #ccc',
      borderLeft: '1px solid #ccc',
      borderRight: '1px solid #ccc',
      padding: 10
    }
  }, React.createElement("select", {
    className: "ql-font",
    title: "Font",
    defaultValue: "sans-serif"
  }, React.createElement("option", {
    value: "sans-serif"
  }, "Sans Serif"), "\n", React.createElement("option", {
    value: "serif"
  }, "Serif"), "\n", React.createElement("option", {
    value: "monospace"
  }, "Monospace")), React.createElement("select", {
    className: "ql-size",
    title: "Size",
    defaultValue: "normal"
  }, React.createElement("option", {
    value: "small"
  }, "Small"), "\n", React.createElement("option", {
    value: "normal"
  }, "Normal"), "\n", React.createElement("option", {
    value: "large"
  }, "Large"), "\n", React.createElement("option", {
    value: "huge"
  }, "Huge")), React.createElement("select", {
    className: "ql-color",
    title: "Text Color",
    defaultValue: "black"
  }, React.createElement("option", {
    value: "white"
  }, "White"), "\n", React.createElement("option", {
    value: "black"
  }, "Black"), "\n", React.createElement("option", {
    value: "red"
  }, "Red"), "\n", React.createElement("option", {
    value: "orange"
  }, "Orange"), "\n", React.createElement("option", {
    value: "yellow"
  }, "Yellow"), "\n", React.createElement("option", {
    value: "green"
  }, "Green"), "\n", React.createElement("option", {
    value: "blue"
  }, "Blue"), "\n", React.createElement("option", {
    value: "purple"
  }, "Purple")), React.createElement("select", {
    className: "ql-background",
    title: "Background Color",
    defaultValue: "white"
  }, React.createElement("option", {
    value: "white"
  }, "White"), "\n", React.createElement("option", {
    value: "black"
  }, "Black"), "\n", React.createElement("option", {
    value: "red"
  }, "Red"), "\n", React.createElement("option", {
    value: "orange"
  }, "Orange"), "\n", React.createElement("option", {
    value: "yellow"
  }, "Yellow"), "\n", React.createElement("option", {
    value: "green"
  }, "Green"), "\n", React.createElement("option", {
    value: "blue"
  }, "Blue"), "\n", React.createElement("option", {
    value: "purple"
  }, "Purple")), React.createElement("select", {
    className: "ql-align",
    title: "Text Alignment",
    defaultValue: "left"
  }, React.createElement("option", {
    value: "left"
  }, "Left"), "\n", React.createElement("option", {
    value: "center"
  }, "Center"), "\n", React.createElement("option", {
    value: "right"
  }, "Right"), "\n", React.createElement("option", {
    value: "justify"
  }, "Justify")), React.createElement("button", {
    className: "ql-bold",
    title: "Bold"
  }, "Bold"), "\n", React.createElement("button", {
    className: "ql-italic",
    title: "Italic"
  }, "Italic"), "\n", React.createElement("button", {
    className: "ql-underline",
    title: "Underline"
  }, "Under"), "\n", React.createElement("button", {
    className: "ql-strike",
    title: "Strikethrough"
  }, "Strike"), "\n", React.createElement("button", {
    className: "ql-link",
    title: "Link"
  }, "Link"), "\n", React.createElement("button", {
    className: "ql-image",
    title: "Image"
  }, "Image"), "\n", React.createElement("button", {
    className: "ql-list",
    title: "Bullet",
    value: "bullet"
  }, "Bullet"), "\n", React.createElement("button", {
    className: "ql-list",
    title: "List",
    value: "ordered"
  }, "List")), React.createElement(Editor, {
    style: {
      border: '1px solid #ccc',
      height: 250
    },
    config: {
      debug: 'info',
      modules: {
        toolbar: '#toolbar-container'
      }
    }
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
